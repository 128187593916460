<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  created() {
    sessionStorage.setItem('lang', 'en')
  },
};
</script>
<style>
</style>
<style>
html,
body {
  margin: 0;
  padding: 0;
}
</style>
