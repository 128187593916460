import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "@/assets/font/commom.css"
import './style/common.less'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// // 引入中英文
import VueI18n from "vue-i18n";
Vue.use(VueI18n);
import en from 'element-ui/lib/locale/lang/en'
import LangEn from "@/assets/lang/en/index.js";

const messages = {
  en: Object.assign(LangEn, en),
};
let _lang = sessionStorage.getItem("lang") || "zh";
const i18n = new VueI18n({
  locale: 'en',
  messages,
});
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value),
});


Vue.config.productionTip = false

router.afterEach((to,from,next) => {
    window.scrollTo(0,0);
  })
new Vue({
  beforeCreate() {
    Vue.prototype.$bus = this
  },
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')
