import Vue from 'vue'
import VueRouter from 'vue-router'
import About from "../views/About"
Vue.use(VueRouter)

const routes = [
  // 首页
  {
    path: '/',
    name: 'About',
    redirect: '/Home',//重定向
  },
  {
    path: '/Home',
    component: About
  },
  // 地书
  {
    path: '/BookFromTheGround',
    name: 'Home',
    component: () => import(/* webpackChunkName:"router-param"*/'../views/Home.vue'),
  },
  // 原地民
  {
    path: '/InhabitantProgram',
    name: 'Aboriginal',
    component: () => import(/* webpackChunkName:"router-param"*/'../views/Aboriginal.vue'),
  },
  // 关于我们
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: () => import(/* webpackChunkName:"router-param"*/'../views/AboutUs.vue'),
  },
  // 创作工具
  {
    path: '/Operation',
    name: 'Operation',
    component: () => import(/* webpackChunkName:"router-param"*/'../views/Operation.vue'),
  },
  // 图片预览
  {
    path: '/Preview',
    name: 'Preview',
    component: () => import(/* webpackChunkName:"router-param"*/'../views/Preview.vue'),
  },
]


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'hash',
  routes
})

router.beforeEach((to, from, next) => {
  // 判断是否是移动端访问  不是移动端访问mobile的值为null
  console.log(to)
  const mobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  if (mobile && to.name !== 'Preview') window.location.href = 'https://m.metawords.io/#/Home'
  else next()
})

export default router
