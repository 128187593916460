<template>
  <div>
    <!-- 中英文切换 -->
    <!-- 导航栏 -->
    <nav-page :opacityFlag='opacityFlag'></nav-page>
    <div class="container-wrap">
      <!-- 顶层背景图片 -->
      <div class="top-img">
        <img class="img5" src="../assets/images/metawords.png" />
      </div>
      <!-- 主视觉背景图片区域 -->
      <div class="top-wrap">
        <div class="left">
          <img class="img4" src="../assets/images/index_logo.svg" alt="">
        </div>
        <div :class="[$i18n.locale == 'en' ? 'right mtl' : 'chright mt2']">
          <span class="fs16 ml39" :class="$i18n.locale == 'en' ? 'eng-span fm3' : 'ch-span fm2'">{{
              $t("about.content.topText")
          }}</span>
          <div class="creat_btn" style="cursor: pointer" @mouseleave="creatLeave($event)"
            @mouseenter="creatEnter($event)">
            <span :class="[$i18n.locale == 'en' ? 'enspan  tx' : 'chspan  tx']" @click="handleLookNet()">{{
                $t("about.content.lookNet")
            }}
              <div class="right_corn"></div>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class='buy'>
      <!-- 发售时间区域 -->
      <div style="width: 1500px; margin: 0 auto">
        <div class="menu-wrap">
          <div class="menu-wrap-one" @mouseleave="handleLeave($event, 1)" @mouseenter="handleChange($event, 1)">
            <span class="one center"></span>
            <span :class="[$i18n.locale == 'en' ? 'eng-text' : 'one-text mt20']">{{ $t('about.content.buyTime')
            }}</span>
          </div>
          <div class="menu-wrap-two" @mouseleave="handleLeave($event, 2)" @mouseenter="handleChange($event, 2)">
            <span class="two center"></span>
            <span :class="[$i18n.locale == 'en' ? 'eng-text' : 'one-text mt20']">{{ $t('about.content.payTime')
            }}</span>
          </div>
          <div class="menu-wrap-three" @mouseleave="handleLeave($event, 3)" @mouseenter="handleChange($event, 3)">
            <span class="three center "></span>
            <span :class="[$i18n.locale == 'en' ? 'eng-text' : 'one-text mt20']">{{ $t('about.content.lowPrice')
            }}</span>
          </div>
          <div class="menu-wrap-four" @mouseleave="handleLeave($event, 4)" @mouseenter="handleChange($event, 4)"
            @click="handlePing" style="cursor:pointer">
            <span class="four center"></span>
            <span :class="[$i18n.locale == 'en' ? 'eng-text' : 'one-text mt20']" style="cursor:pointer">{{
                $t('about.content.market')
            }}</span>
          </div>
        </div>

        <!-- 拍卖字符背景图 -->
        <div class="sell-words">
          <div>
            <img src="../assets/images/sell_words.png" />
            <span class="fs20 public" :class="[$i18n.locale == 'en' ? 'fm10' : 'fm6']">{{ $t("about.content.sellWords")
            }}</span>
          </div>
        </div>
        <div class="video-wrap">
          <img class="people" src="../assets/images/index_people.png" alt="" />
          <div class="content">
            <span class="top m img6 fs21" :class="[$i18n.locale == 'en' ? 'fm1' : 'fm7']">{{ $t('about.listen.list_one')
            }}</span>
            <a class="center" href="https://lionworld.oss-cn-hongkong.aliyuncs.com/What+Is+MetaWords.mp4"
              target="_blank">
              <img src="https://lionworld.oss-cn-hongkong.aliyuncs.com/metawords/source-1.png" />
              <img src="../assets/images/play_button.png" />
            </a>
            <div class="bottom">
              <img class="img7" src="../assets/images/index_icon1.png" alt="" />
              <div class="text">
                <span class="texts" :class="[$i18n.locale == 'en' ? 'fs16 fm1' : 'fs20 fm7']">{{
                    $t('about.listen.meatawords')
                }}</span>
                <span class="fs20 fm11" :style="{ marginTop: $i18n.locale == 'en' ? '0px' : '20px' }">03:39</span>
              </div>
            </div>
          </div>
          <div class="content">
            <span class="top m img6 fs21" :class="[$i18n.locale == 'en' ? 'fm1' : 'fm7']">{{ $t('about.listen.list_two')
            }}</span>
            <a class="center" href="https://lionworld.oss-cn-hongkong.aliyuncs.com/Economic+Model.mp4" target="_blank">
              <img src="https://lionworld.oss-cn-hongkong.aliyuncs.com/metawords/source-3.png" />
              <img src="../assets/images/play_button.png" />
            </a>
            <div class="bottom">
              <img class="img7" style="width: 40px; height: 33px" src="../assets/images/index_icon2.png" />
              <div class="text">
                <span class="texts" :class="[$i18n.locale == 'en' ? 'fs16 fm1' : 'fs20 fm7']">{{
                    $t('about.listen.economic')
                }}</span>
                <span class="fs20 fm11" :style="{ marginTop: $i18n.locale == 'en' ? '0px' : '20px' }">02:46</span>
              </div>
            </div>
          </div>
          <div class="content">
            <span class="top m img6 fs21" :class="[$i18n.locale == 'en' ? 'fm1' : 'fm7']">{{
                $t("about.listen.list_three")
            }}</span>
            <a class="center" href="https://lionworld.oss-cn-hongkong.aliyuncs.com/How+to+Purchase.mp4" target="_blank">
              <img src="https://lionworld.oss-cn-hongkong.aliyuncs.com/metawords/source-2.png" />
              <img src="../assets/images/play_button.png" />
            </a>
            <div class="bottom">
              <img class="img7" style="width: 50px; height: 55px" src="../assets/images/index_icon3.png" />
              <div class="text">
                <span class="texts" :class="[$i18n.locale == 'en' ? 'fs16 fm1' : 'fs20 fm7']">{{
                    $t('about.listen.buyWords')
                }}</span>
                <span class="fs20 fm11" :style="{ marginTop: $i18n.locale == 'en' ? '35px' : '20px' }">02:04</span>
              </div>
            </div>
          </div>
        </div>

        <!-- 白皮书区域 -->
        <div class="white-book">
          <div class="left">
            <img src="../assets/images/White_Paper.png" />
          </div>
          <div class="right">
            <div class="white_item left_item"><img src="../assets/images/index_icon4.png" /></div>
            <div class="white_item right_item mt25">
              <span class="white  fs21" :class="[$i18n.locale == 'en' ? 'fm13' : 'fm12']">{{
                  $t('about.whiteBookText.whiteBookTop')
              }}</span>
              <span class="white  fs21" :class="[$i18n.locale == 'en' ? 'fm13' : 'fm12']">{{
                  $t('about.whiteBookText.whiteBookBottom')
              }}</span>
              <!-- <div class="right-down-top">
              <div @click="handleWhite()" style="cursor: pointer">
                <p>下载白皮书</p>
                <img src="../assets/images/index_icon5.png" />
              </div>
              <div @click="handleEasy()" style="cursor: pointer; z-index: 999">
                <p>下载简册</p>
                <img src="../assets/images/index_icon5.png" />
              </div>
            </div> -->
              <div class="right-down-bootom">
                <div @click="handleWhitePaper()" style="cursor: pointer">
                  <p style="fontfamily: Montserrat Bold">English White Paper</p>
                </div>
                <div @click="handleBrochure()" style="cursor: pointer">
                  <p style="fontfamily: Montserrat Bold">English Brochure</p>
                </div>
              </div>
            </div>
          </div>
          <div class="book-bg">
            <img src="../assets/images/index_whiteBook2.png" />
          </div>
        </div>
      </div>
    </div>

    <div style="width: 1500px; margin: 0 auto">
      <!-- Roadmap区域 -->
      <div class="Roadmap">
        <div class="Roadmap-img">
          <img class="img55" src="../assets/images/index_icon6.png" />
          <span class="fs64 fm1">Roadmap</span>
        </div>
        <p class="chp fs21" :class="[$i18n.locale == 'en' ? 'fm14' : 'fm12']">{{ $t('about.whiteBookText.romapText') }}
        </p>
      </div>

      <!-- 步骤条区域 -->
      <div class="progress">
        <div class="left">
          <img src="../assets/images/index_icon7.png" alt="" />
        </div>
        <div class="center">
          <span class="wm1 enspan fs21" :class="[$i18n.locale == 'en' ? ' fm14' : 'fm12']"
            :style="{ marginTop: $i18n.locale == 'en' ? '69px' : '75px' }">{{ $t('about.whiteBookText.progressText1')
            }}</span>
          <span class="wm2 enspan fs21" :class="[$i18n.locale == 'en' ? ' fm14' : 'fm12']"
            :style="{ marginTop: $i18n.locale == 'en' ? '80px' : '105px', width: '500px' }">{{
                $t('about.whiteBookText.progressText2')
            }}</span>
          <span class="wm3 enspan fs21" :class="[$i18n.locale == 'en' ? ' fm14' : 'fm12']"
            :style="{ marginTop: $i18n.locale == 'en' ? '20px' : '50px', width: '280px', }">{{
                $t('about.whiteBookText.progressText3')
            }}</span>
          <span class="wm4 enspan fs21" :class="[$i18n.locale == 'en' ? ' fm14' : 'fm12']"
            :style="{ marginTop: $i18n.locale == 'en' ? '100px' : '105px' }">{{ $t('about.whiteBookText.progressText4')
            }}</span>
          <span class="wm5 enspan fs21" :class="[$i18n.locale == 'en' ? ' fm14' : 'fm12']"
            :style="{ marginTop: $i18n.locale == 'en' ? '80px' : '110px', width: '500px' }">{{
                $t('about.whiteBookText.progressText5')
            }}</span>
          <span class="wm6 enspan fs21" :class="[$i18n.locale == 'en' ? ' fm14' : 'fm12']"
            :style="{ marginTop: $i18n.locale == 'en' ? '20px' : '75px' }">{{ $t('about.whiteBookText.progressText6')
            }}</span>
          <span class="wm7 enspan fs21" :class="[$i18n.locale == 'en' ? ' fm14' : 'fm12']"
            :style="{ marginTop: $i18n.locale == 'en' ? '40px' : '75px' }">{{ $t('about.whiteBookText.progressText7')
            }}</span>
        </div>
        <div class="right">
          <img src="../assets/images/index_icon8.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 底部 背景区域 -->
    <div class="bg-wrap">
      <div class="bg-span">
        <img class="img1" src="../assets/images/index_icon9.png" />
        <div class="bg-intop">
          <img class="img2" src="../assets/images/index_icon10.png" />
          <span class="bg-text">
            <span :style="{ width: $i18n.locale == 'en' ? '700px' : '570px' }">
              <img class="img3" src="../assets/images/index_icon6.png" />
              {{ $t('about.whiteBookText.inhabitant') }}
            </span>
            <span class="mt30" :class="[$i18n.locale == 'en' ? 'entext2 fm14 fs16' : 'chtext2 fm12 fs21']">{{
                $t('about.whiteBookText.inhabitantText')
            }}</span>
            <span class="fs24 entext3" :class="[$i18n.locale == 'en' ? 'fm15' : 'fm7']" @click="handleView()">{{
                $i18n.locale == 'en' ? "View Details" : "查看详情"
            }}</span>
          </span>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="content">
        <div class="footer-top">
          <div class="left">
            <img src="../assets/images/index_icon11.png" />
            <span class="fs48 ml32 enspan" :class="[$i18n.locale == 'en' ? 'fm16' : 'fm17']">{{
                $t('about.whiteBookText.community')
            }}</span>
            <div class="fs21" :class="[$i18n.locale == 'en' ? 'enleft-text mt24 fm18' : 'chleft-text mt50 fm19']">
              {{ $t('about.whiteBookText.footerText') }}</div>
          </div>
          <div class="right">
            <img src="../assets/images/index_phone.png" />
          </div>
        </div>
        <div class="foot">
          <a style="text-decoration: none" href=" https://discord.com/invite/FFmUt3rp5J" target="_blank">
            <span class="foot-span">
              <img src="../assets/images/index_discord.png" />
              <span class="fs24 fm1">Discord</span>
            </span>
          </a>
          <a style="text-decoration: none" href="https://t.me/MetaWordsNFTDiscussion" target="_blank">
            <span class="foot-span">
              <img src="../assets/images/index_telegram.png" />
              <span class="fs24 fm1">Telegram</span>
            </span>
          </a>
          <a style="text-decoration: none" href="https://twitter.com/MetaWordsNFT" target="_blank">
            <span class="foot-span">
              <img src="../assets/images/index_twitter.png" />
              <span class="fs24 fm1">Twitter</span>
            </span>
          </a>
        </div>
      </div>
    </div>
    <!-- 最底部 -->
    <footer-page></footer-page>
  </div>
</template>
<script>
// import headerPage from "@/components/header.vue"//中英文切换
import navPage from "@/components/header_nav.vue"//导航栏
import footerPage from "@/components/footer.vue"//脚部
export default {
  components: { navPage, footerPage },
  data() {
    return {
      show: false,
      opacityFlag: 1,
    };;
  },
  methods: {
    // 点击黑色looknet按钮
    handleLookNet() {
      this.$router.push('/Operation')
      // window.open("https://lionnft.io/", "_self");
    },
    // 交易平台
    handlePing() {
      window.open("https://lionnft.io/", "_self");
    },
    // 查看详情
    handleView() {
      this.$router.push("InhabitantProgram");
    },
    // 鼠标进入
    handleChange($event, type) {
      if (this.$i18n.locale == 'en') {
        // 英文
        if (type == 1) {
          $event.currentTarget.className = "menu-wrap-enone";
        } else if (type == 2) {
          $event.currentTarget.className = "menu-wrap-entwo";
        } else if (type == 3) {
          $event.currentTarget.className = "menu-wrap-enthree";
        } else {
          $event.currentTarget.className = "menu-wrap-enfour";
        }
      } else {
        if (type == 1) {
          $event.currentTarget.className = "menu-wrap-chone";
        } else if (type == 2) {
          $event.currentTarget.className = "menu-wrap-chtwo";
        } else if (type == 3) {
          $event.currentTarget.className = "menu-wrap-chthree";
        } else {
          $event.currentTarget.className = "menu-wrap-chfour";
        }
      }
    },
    // 鼠标离开
    handleLeave($event, type) {
      if (type == 1) {
        $event.currentTarget.className = "menu-wrap-one";
      } else if (type == 2) {
        $event.currentTarget.className = "menu-wrap-two";
      } else if (type == 3) {
        $event.currentTarget.className = "menu-wrap-three";
      } else {
        $event.currentTarget.className = "menu-wrap-four";
      }
    },
    //修改creatNow样式
    creatLeave($event) {
      $event.currentTarget.className = "creat_btn";
    },
    creatEnter($event) {
      $event.currentTarget.className = "leave_btn";
    },
    // 下载白皮书
    handleWhite() {
      window.open("https://lionworld.oss-cn-hongkong.aliyuncs.com/pdf/MetaWords%E7%99%BD%E7%9A%AE%E4%B9%A6.pdf", "_blank");
    },
    // English White Paper
    handleWhitePaper() {
      window.open("https://lionworld.oss-cn-hongkong.aliyuncs.com/pdf/MetaWords+WHITE+PAPER.pdf", "_blank")
    },
    // English Brochure
    handleBrochure() {
      window.open("https://lionworld.oss-cn-hongkong.aliyuncs.com/pdf/MetaWords+Eng+Intro+Dec.pdf", "_blank");
    },
    // 下载简册
    handleEasy() {
      window.open("https://lionworld.oss-cn-hongkong.aliyuncs.com/pdf/MetaWords%E6%A8%AA%E7%89%88%E4%B8%AD%E6%96%87%E4%BB%8B%E7%BB%8DVer1.pdf", "_blank");
    },
  },
};
</script>
<style scoped>
</style>
<style lang="less" scoped>
@import "../assets/css/commom.less";

.container-wrap {
  margin: 0 auto;
  height: 100%;
  width: 1500px;
}

// 顶层背景图片样式
.top-img {
  width: 867px;
  margin: 60px auto 0;
}

// 主视觉背景图片区域
.top-wrap {
  display: flex;
  margin: 0 auto 180px;

  .left {
    padding-left: 344px;
    position: relative;
    top: -62px;
  }

  .right {
    span {
      display: block;
    }

    .eng-span {
      width: 403px;
      height: 139px;
      line-height: 24px;
    }
  }

  .chright {
    span {
      display: block;
    }

    .ch-span {
      width: 403px;
      line-height: 19px;
    }

    .black-btn {
      margin: 79px 0 0 50px;
    }
  }
}

.creat_btn {
  margin: 79px 0 0 50px;
  height: 53px;
  background: #000000;
  border-radius: 7px;
  line-height: 53px;
  font-family: Montserrat-Medium, Montserrat;
  font-weight: 700;
  color: #ffffff;
  font-size: 26px;
  margin-top: 50px;
  margin-left: 39px;
  letter-spacing: 1px;

  .right_corn {
    display: inline-block;
    vertical-align: middle;
    width: 34px;
    height: 34px;
    background: url('../assets/images/index_icon_leave.png') center no-repeat;
    margin-bottom: 5px;
    margin-left: 5px;
  }
}

.leave_btn {
  margin: 79px 0 0 50px;
  height: 47px;
  background: #FFFFFF;
  border-radius: 7px;
  line-height: 47px;
  font-family: Montserrat-Medium, Montserrat;
  font-weight: 700;
  color: #000000;
  font-size: 26px;
  margin-top: 50px;
  margin-left: 39px;
  border: 3px solid #000000;
  letter-spacing: 1px;

  .right_corn {
    display: inline-block;
    width: 34px;
    height: 34px;
    background: url('../assets/images/index_icon_enter.png') no-repeat;
    vertical-align: middle;
    margin-bottom: 5px;
    margin-left: 5px;
  }
}

.buy {
  min-width: 1500px;
  box-shadow: 0px -1px 20px 14px rgba(0, 0, 0, 0.06);
  position: relative;
  padding-top: 180px;
  padding-bottom: 75px;

  // 发售时间区域
  .menu-wrap {
    display: flex;
    position: absolute;
    top: -150px;
    left: 47%;
    transform: translate(-50%);

    .eng-text {
      width: 151px;
      text-align: center;
      margin: 0 auto;
      margin-top: 20px;
      font-size: 16px;
      font-family: Montserrat-Medium, Montserrat;
      font-weight: 500;
      color: #000000;
    }

    // 日期
    .menu-wrap-one {
      .one {
        background-image: url("../assets/images/index_date.svg");
        background-repeat: no-repeat;
        display: block;
        width: 120px;
        height: 120px;
      }
    }

    // 中文页面鼠标hover上去样式
    .menu-wrap-chone {
      background-color: #000000;

      .one {
        background-image: url("../assets/images/index_icon12.png");
        background-repeat: no-repeat;
        display: block;
        width: 152px;
        height: 120px;
      }

      .one-text {
        color: #ffffff;
      }
    }

    //英页面鼠标hover上去样式
    .menu-wrap-enone {
      background-color: #000000;

      .one {
        background-image: url("../assets/images/index_icon12_copy.png");
        background-repeat: no-repeat;
        display: block;
        width: 152px;
        height: 120px;
      }

      .eng-text {
        width: 151px;
        text-align: center;
        font-size: 16px;
        font-family: Montserrat-Medium, Montserrat;
        font-weight: 500;
        color: #ffffff;
      }
    }

    // 拍卖
    .menu-wrap-two {
      .two {
        background-image: url("../assets/images/inedx_asset.svg");
        background-repeat: no-repeat;
        display: block;
        width: 120px;
        height: 120px;
      }
    }

    // 中文页面鼠标hover上去样式
    .menu-wrap-chtwo {
      background-color: #000000;

      .two {
        background-image: url("../assets/images/index_icon13.png");
        background-repeat: no-repeat;
        display: block;
        width: 152px;
        height: 120px;
      }

      .one-text {
        color: #ffffff;
      }
    }

    //英页面鼠标hover上去样式
    .menu-wrap-entwo {
      background-color: #000000;

      .two {
        background-image: url("../assets/images/index_icon13.png");
        background-repeat: no-repeat;
        display: block;
        width: 152px;
        height: 120px;
      }

      .eng-text {
        width: 151px;
        text-align: center;
        font-size: 16px;
        font-family: Montserrat-Medium, Montserrat;
        font-weight: 500;
        color: #ffffff;
      }
    }

    // 第三个
    .menu-wrap-three {
      .three {
        background-image: url("../assets/images/index_assets_copy.svg");
        background-repeat: no-repeat;
        display: block;
        width: 120px;
        height: 120px;
      }
    }

    // 中文页面鼠标hover上去样式
    .menu-wrap-chthree {
      background-color: #000000;

      .three {
        background-image: url("../assets/images/index_icon12.png");
        background-repeat: no-repeat;
        display: block;
        width: 152px;
        height: 120px;
      }

      .one-text {
        color: #ffffff;
      }
    }

    //英页面鼠标hover上去样式
    .menu-wrap-enthree {
      background-color: #000000;

      .three {
        background-image: url("../assets/images/index_icon12_copy.png");
        background-repeat: no-repeat;
        display: block;
        width: 152px;
        height: 120px;
      }

      .eng-text {
        width: 151px;
        text-align: center;
        font-size: 16px;
        font-family: Montserrat-Medium, Montserrat;
        font-weight: 500;
        color: #ffffff;
        margin-bottom: 20px;
      }
    }

    // 第四个
    .menu-wrap-four {
      .four {
        background-image: url("../assets/images/index_ch_lion.svg");
        background-repeat: no-repeat;
        display: block;
        width: 120px;
        height: 120px;
      }
    }

    // 中文页面鼠标hover上去样式
    .menu-wrap-chfour {
      background-color: #000000;

      .four {
        background-image: url("../assets/images/index_en_lion.svg");
        background-repeat: no-repeat;
        display: block;
        width: 120px;
        height: 120px;
      }

      .one-text {
        color: #ffffff;
      }
    }

    //英页面鼠标hover上去样式
    .menu-wrap-enfour {
      background-color: #000000;

      .four {
        background-image: url("../assets/images/index_en_lion.svg");
        background-repeat: no-repeat;
        display: block;
        width: 120px;
        height: 120px;
      }

      .eng-text {
        width: 151px;
        text-align: center;
        font-size: 16px;
        font-family: Montserrat-Medium, Montserrat;
        font-weight: 500;
        color: #ffffff;
      }
    }

    div {
      margin-right: 40px;
      padding-top: 24px;
      text-align: center;
      width: 165px;
      height: 213px;
      background: #ffffff;
      box-shadow: 0px -1px 20px 14px rgba(0, 0, 0, 0.06);
      border-radius: 17px;

      img {
        display: block;
        margin: 0 auto;
        width: 152px;
        height: 120px;
      }

      span {
        display: block;
        font-size: 21px;
        font-family: NotoSansSC-Medium, NotoSansSC;
        font-weight: 500;
        color: #000000;
        line-height: 24px;
      }
    }

    div:nth-child(1) {
      margin-left: 120px;
    }
  }
}

.sell-words {
  width: 830px;
  margin: 0 auto;

  div {
    width: 830px;
    border-bottom: 3px solid #000000;
    text-align: center;

    img {
      display: block;
      width: 830px;
      height: 223px;
    }

    .public {
      display: block;
      margin: 17px 0 21px;
      line-height: 29px;
    }
  }
}

//  小讲堂区域
.video-wrap {
  position: relative;
  display: flex;
  margin: 0 auto;
  padding-left: 309px;

  .people {
    position: absolute;
    top: -10px;
    left: 90px;
    height: 452px;
    width: 169px;
  }

  .content {
    margin: 70px 0 0 18px;
    width: 264px;
    height: 373px;
    background: #ffffff;
    box-shadow: 0px -1px 20px 14px rgba(0, 0, 0, 0.06);
    border-radius: 17px;

    .top {
      display: block;
      line-height: 32px;
    }

    .center {
      position: relative;
      display: block;
      margin-top: 18px;

      img:nth-child(1) {
        height: 169px;
        width: 264px;
      }

      img:nth-child(2) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 85px;
        height: 85px;
      }
    }

    .bottom {
      display: flex;
      padding: 0 10px;

      .text {
        margin: 9px 0 0 10px;

        span {
          display: block;
        }

        .texts {
          width: 194px;
          height: 32px;
          line-height: 32px;
          -webkit-text-fill-color: transpar;
        }

        span:nth-child(2) {
          width: 56px;
          height: 32px;
          line-height: 32px;
        }
      }
    }
  }
}

// 白皮书区域样式
.white-book {
  // display: flex;
  position: relative;
  // width: 55%;
  margin: 91px auto 0px;
  padding-left: 338px;

  .left {
    position: relative;

    img:nth-child(2) {
      position: absolute;
      top: 59px;
      left: 46%;
      transform: translate(-50%);
    }
  }

  .right {
    margin-top: -5px;

    .white_item {
      display: inline-block;
    }

    .right_item {
      margin-left: 71px;
      vertical-align: top;
    }

    .left_item {
      margin-left: 63px;
    }

    .white {
      display: block;
      width: 524px;
      line-height: 25px;
    }

    .right-down-top,
    .right-down-bootom {
      display: flex;
      margin-top: 40px;

      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 238px;
        height: 55px;
        background: #ffffff;
        border-radius: 20px;
        border: 4px solid #000000;

        p {
          margin-left: 23px;
          width: 179px;
          height: 30px;
          font-size: 21px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 550;
          color: #000000;
          line-height: 29px;
        }

        img {
          margin-right: 19px;
        }
      }

      div:nth-child(2) {
        margin-left: 30px;
      }
    }

    .right-down-bootom {
      margin-top: 20px;

      div {
        text-align: center;

        p {
          margin-left: 0;
          width: 100%;
        }
      }
    }
  }

  .book-bg {
    position: absolute;
    top: 0;
    right: 100px;
  }
}

// Roadmap区域样式
.Roadmap {
  margin: 81px auto 0;

  .Roadmap-img {
    width: 406px;
    display: flex;
    align-items: center;
    margin: 0 auto 31px;

    span {
      width: 324px;
      height: 78px;
      line-height: 78px;
      padding-left: 27px;
    }
  }

  .chp {
    display: block;
    margin: 0 auto;
    width: 752px;
    height: 37px;
    line-height: 30px;
    text-align: center;
  }
}

// 步骤条区域
.progress {
  display: flex;
  margin: 45px auto 0;
  padding-left: 370px;

  .left {
    height: 1032px;
    width: 169px;
  }

  .center {
    margin-left: 15px;

    .enspan {
      display: block;
      line-height: 30px;
      width: 403px;
    }
  }

  .right {
    margin-top: 100px;
    position: relative;
    right: 63px;
  }
}

.bg-wrap {
  display: flex;
  margin-top: 111px;
  width: 100%;

  .bg-span {
    display: flex;
    position: relative;
    width: 100%;

    .img1 {
      min-width: 1500px;
      width: 100%;
      background-size: cover;
    }

    .bg-intop {
      .img2 {
        width: 849px;
        height: 447px;
        background-size: cover;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .img3 {
        height: 39px;
        width: 39px;
        margin-right: 30px;
      }

      .bg-text {
        position: absolute;
        left: 50%;
        top: 45%;
        width: 570px;
        transform: translate(-50%, -50%);

        span {
          display: block;
        }

        span:nth-child(1) {
          font-size: 36px;
          font-family: Montserrat-Bold, Montserrat;
          font-weight: bold;
          color: #000000;
          margin-right: 40px;
          position: relative;
          right: 80px;
        }

        .entext3 {
          margin-top: 37px;
          margin-left: 210px;
          cursor: pointer;
        }
      }
    }
  }
}

.footer {
  display: flex;
  min-width: 1500px;
  height: 558px;
  background: #000000;
  border-bottom: 1px solid #ffffff;

  .content {
    margin: 0 auto;
  }

  .footer-top {
    display: flex;
    justify-content: space-between;
    padding: 114px 170px 0;

    .right {
      height: 190px;
      width: 170px;
      padding-right: 30px;
    }

    .left {
      height: 100px;
      padding-left: 20px;

      .enspan {
        width: 403px;
        height: 120px;
        line-height: 70px;
      }

      .enleft-text {
        width: 453px;
        height: 90px;
        line-height: 30px;
      }

      .chleft-text {
        width: 392px;
        height: 90px;
        line-height: 30px;
      }
    }
  }

  .foot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 63px auto 0px;
    padding: 0 180px;

    .foot-span {
      display: flex;
      align-items: center;
      padding-left: 24px;
      background: #ffffff;
      border-radius: 20px;
      border: 4px solid #ffffff;
      margin-right: 59px;

      span {
        width: 133px;
        height: 49px;
        line-height: 49px;
        margin-left: 23px;
      }
    }
  }
}
</style>