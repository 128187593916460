const aboutUs = {
    aboutUs:"About Us",
    text1:`Flying Lion Lab is an innovative Metaverse laboratory co-founded by artist Xu Bing and Lion Group Holding Ltd. Flying Lion Lab undertakes the journey to expand Xu Bing’s artwork into Non-Fungible Tokens (NFTs) and is responsible for helping them develop within the Metaverse – creating a new art ecosystem.`,
    text2:`MetaWords is Flying Lion Lab’s first project and is an evolution of Xu Bing’s artwork Book from the Ground. This NFT project has the potential to continuously grow and flourish, as an infinite work of art. Flying Lion Lab hopes it will serve as an entirely new language system of the future Metaverse, the “Metalanguage” of the Metaverse. MetaWords is a language ecosystem that is truly open, where the community can freely participate, grow, and develop together. `,
    text3:`At the frontier of exploring the emerging field of the NFT, centered around the cornerstone that Xu Bing has paved, Flying Lion Lab has already started building strategic partnerships. These include various intellectual property resources such as top talent agencies, art galleries, museums, and other renowned artists. The Flying Lion Lab team is comprised of a mix of talented individuals with diverse backgrounds, including those in key financial institutions, multi-national technology companies as well as art galleries.`,
    text4:`Need to contact us?`,
    text5:`If you have any inquiries about MetaWords or would like to partner with us, please contact us via the following methods:`,
    text6:`1. Email us at metawords@flyinglion.io`,
    text7:`2. Tweet us your questions on Twitter @MetaWordsNFT`,
    text8:`3. Join our Discord server and connect with other community members`
}
export {aboutUs}