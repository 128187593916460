const about = {
    content:{
        topText:`MetaWords, an NFT art project, is the evolution of Xu Bing’s art-work Book from the Ground in the Metaverse. MetaWords aims to invent an entirely new language system of the future Metaverse–The“Meta-language” of the Metaverse–to achieve Xu Bing’s ideal of a universal script.`,
        lookNet:"CREATE NOW!",
        buyTime:"RELEASE DATE",
        payTime:"AUCTION AMOUNT",
        lowPrice:"LOWEST CHARACTER PRICE",
        market:"MARKET PLATFORM",
        sellWords:'Preview of the first 30 characters up for auction'
    },
    listen:{
        list_one:"Get to know MetaWords (I)",
        meatawords:'What Is MetaWords',
        list_two:"Get to know MetaWords (II)",
        economic:"Economic Model",
        list_three:"Get to know MetaWords (III)",
        buyWords:'Auction & Blind Box MetaWords Value',
    },
    whiteBookText:{
        whiteBookTop:`The MetaWords White Paper is a very important guiding document in the development of MetaWords for the Flying Lion Lab. `,
        whiteBookBottom:`You can find answers here to most questions you may have about MetaWords.`,
        romapText:'More MetaWords features are under development. We will provide more updates on its progress.',
        progressText1:`MetaWords release. The first NFT bundle will be up for auction.`,
        progressText2:`Lion NFT Platform will launch a MetaWords NFT creation-tool, giving users the ability to create new MetaWords NFTs as well as trade them on the Lion NFT platform.`,
        progressText3:`Blind box release.`,
        progressText4:`MetaWords connects to Metaverse platforms.`,
        progressText5:`MetaWords engages with social networks. Users can showcase their NFT works across a variety of platforms and can interact with other users using the MetaWords language.`,
        progressText6:`MetaWords invites inhabitants of the Metaverse to take part in contributing to the MetaWords lexicon.`,
        progressText7:`MetaWords launches the language translator. MetaWords characters  support input using any of the world’s major languages.`,
        inhabitant:"MetaWords Inhabitant Program",
        inhabitantText:`We are pleased to announce the official launch of the MetaWords Inhabitant Program in an effort to provide better resources to our community friends who are growing with MetaWords. We hope you can work with us in today’s blooming market alongside the promising development of MetaWords. It’s a win-win! We look forward to having you join us!`,
        community:'Join the Community',
        footerText:`In the MetaWords Community, you can find the newest updates and answers to frequently asked questions, as well as connect with other inhabitants.`,
    
    
    
    
    
    }
}
export {about}