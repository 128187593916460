<template>
  <div>
    <div class="nav">
      <div class="nav-wrap">
        <div class="left">
          <img style="cursor: pointer" class="logo-left" src="../assets/images/home_logo.svg" @click="handleBack"/>
          <span class="MetaWords" @click="handleBack" style="cursor: pointer">MetaWords</span>
        </div>
        <div class="right">
          <div style="display: flex;justify-content:flex-end;align-items: center;">
            <span :class="[$i18n.locale == 'en' ? 'enspan' : 'chspan']" id="t1" @click="handleIndex">{{$t("nav.home")}}</span>
            <span :class="[$i18n.locale == 'en' ? 'enspan' : 'chspan']" id="t2" @click="handleLowBook" :style="{ marginRight:$i18n.locale == 'en' ? '24px' : '' }">{{$t("nav.book")}}</span>
            <span :class="[$i18n.locale == 'en' ? 'enspan' : 'chspan']" id="t3" @click="handleProressProgram">{{$t("nav.gram")}}</span>
          </div>
          <div class="icon-wrap" style="margin-left: 15px">
            <a href="https://t.me/MetaWordsNFTDiscussion" target="_blank">
              <img class="img1" src="../assets/images/nav_icon1.png" />
            </a>
            <a href="https://twitter.com/MetaWordsNFT" target="_blank">
              <img class="img2" src="../assets/images/nav_icon2.png" />
            </a>
            <a href="https://discord.com/invite/FFmUt3rp5J" target="_blank">
              <img class="img3" src="../assets/images/nav_icon3.png" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    opacityFlag: Number,
  },
  data() {
    return {
    };
  },
  mounted() {
    if (this.opacityFlag == 1) {
      document.getElementById("t1").style.opacity = "45%";
    } else if (this.opacityFlag == 2) {
      document.getElementById("t2").style.opacity = "45%";
    } else if(this.opacityFlag == 3){
      document.getElementById("t3").style.opacity = "45%";
    }
  },
  methods: {
    // 点击地书
    handleLowBook() {
      // this.$parent.handleBook();
      this.$router.push("/BookFromTheGround");
    },
    // 点击原住民计划
    handleProressProgram() {
        this.$router.push("/InhabitantProgram");
    },
    // 点击首页
    handleIndex() {
      this.$router.push('/Home');
    },
    // 点击返回首页
    handleBack() {
      this.$router.push('/Home');
    },
  },
};
</script>
<style lang="scss" scoped>
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: 100px;
  min-width: 1500px;
  box-shadow: 0px -1px 20px 14px rgba(0, 0, 0, 0.06);
  // 导航区域样式
  .nav-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    height: 100px;
    width: 1050px;
    .left {
      display: flex;
      align-items: center;
      flex: 1;
      .logo-left {
        width: 52px;
        height: 52px;
      }
      .MetaWords {
        font-size: 24px;
        font-family: Montserrat-Bold, Montserrat;
        font-weight: bold;
        color: #171826;
        padding-left: 20px;
      }
    }
    // 首页地书及图标样式
    .right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 1;
      .tl {
        color: red;
      }
      .enspan {
        width: 140px;
        font-size: 16px;
        font-family: Montserrat-Bold, Montserrat;
        font-weight: bold;
        color: #000000;
        cursor: pointer;
        text-align: center;
      }
      .chspan {
        width: 140px;
        font-size: 21px;
        font-family: NotoSansSC-Bold, NotoSansSC;
        font-weight: bold;
        color: #000000;
        cursor: pointer;
      }
      .icon-wrap {
        width: 200px;
        a {
          margin-right: 26px;
        }
        a:nth-child(3) {
          margin-right: 0;
        }
      }
    }
  }
}
</style>