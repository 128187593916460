<template>
    <div>
        <div class="footer-bottom">
      <div class="footer-wrap">
        <div class="left">
          <img class="logo" src="../assets/images/footer_icon4.svg" />
          <span class="logo-text">MetaWords</span>
        </div>
        <div class="center">
          <div style="margin-top: 20px">
            <span
              :class="[$i18n.locale == 'en' ? 'enfooterspan' : 'chfooterspan']"
              style="cursor: pointer"
              @click="handleUs"
              >{{ $i18n.locale == 'en' ? "About Us" : "关于我们" }}</span
            >

            <span
              :class="[$i18n.locale == 'en' ? 'enfooterspan' : 'chfooterspan']"
              style="cursor: pointer"
              @click="handleJumpMarket"
              >{{ $i18n.locale == 'en' ? "Marketplace" : "交易市场" }}</span
            >
            <span
              style="cursor: pointer"
              :class="[$i18n.locale == 'en' ? 'enfooterspan' : 'chfooterspan']"
              @click="handleToman"
              >{{ $i18n.locale == 'en' ? "Inhabitant Program" : "成为原住民" }}</span
            >
          </div>
        </div>
        <div class="right">
          <div class="right-wrap">
            <a href="https://t.me/MetaWordsNFTDiscussion" target="_blank">
              <img class="img1" src="../assets/images/footer_icon1.png" />
            </a>
            <a href="https://twitter.com/MetaWordsNFT" target="_blank">
              <img class="img2" src="../assets/images/footer_icon2.png" />
            </a>
            <a href="https://discord.com/invite/FFmUt3rp5J" target="_blank">
              <img class="img3" src="../assets/images/footer_icon3.png" />
            </a>
          </div>
        </div>
      </div>
    </div>
    </div>
</template>
<script>
export default {
    data(){
        return{

        }
    },
    methods:{
        // 关于我们
        handleUs(){
           this.$router.push("AboutUs");
        },
        // 交易市场
        handleJumpMarket(){
            window.open("https://lionnft.io/", "_self");
        },
        // 成为原住民
        handleToman(){
          this.$router.push("InhabitantProgram");
        }
    }
}
</script>
<style lang="scss" scoped>
.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: 350px;
  min-width: 1500px;
  background: #000000;
  .footer-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto 100px;
    width: 1000px;
    .left {
      display: flex;
      align-items: center;
      margin-bottom: 70px;
      .logo {
        width: 61px;
        height: 61px;
      }
      .logo-text {
        font-size: 24px;
        font-family: Montserrat-Bold, Montserrat;
        font-weight: bold;
        color: #ffffff;
        padding-left: 29px;
      }
    }
    .center {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // span {
      //   width: 172px;
      //   font-size: 17px;
      //   font-family: NotoSansSC-Bold, NotoSansSC;
      //   font-weight: 500;
      //   color: #ffffff;
      //   line-height: 24px;
      // }
      .enfooterspan {
        width: 172px;
        font-size: 17px;
        font-family: Montserrat-Medium, Montserrat;
        font-weight: 500;
        color: #ffffff;
        line-height: 24px;
      }
      .chfooterspan {
        width: 172px;
        font-size: 17px;
        font-family: NotoSansSC-Bold, NotoSansSC;
        font-weight: 500;
        color: #ffffff;
        line-height: 24px;
      }
      span:nth-child(1) {
        padding-top: 21px;
      }

      span:nth-child(2) {
        display: block;
        margin-top: 21px;
        margin-bottom: 21px;
      }
    }
    .right {
      margin-bottom: 60px;
      a:nth-child(1) {
        margin-right: 22px;
      }
      a:nth-child(2) {
        margin-right: 22px;
      }
    }
  }
}
</style>