const book = {
    book:'Book from the Ground:From Point to Point',
    text1:`In 2012, Xu Bing published the first book in the world that used public signs and symbols, Book from the Ground: From Point to Point. The book tells a story of the protagonist,`,
    text1Copy: `, (the human figure from the men’s restroom sign), a typical white-collar worker, and his ordinary life in a day. During the process of creating Book from the Ground, Xu Bing invented a consistent and logical language system that is easy to identify and understand, using comprehensive symbols to tell a story. MetaWords is a language system born of Book from the Ground.`,
    text2:`The work, Book from the Ground, is a vital guiding document in the development of MetaWords. By referring to the storyline and the methods associated with use of its symbols,  inhabitants in the Metaverse are imparted the freedom to create their own unique works with MetaWords. They can even invent new grammatical and rhetorical devices beyond existing linguistic conventions, which will forward the goal of MetaWords becoming the “Meta-language” within the Metaverse.`,
    footerText:`Xu Bing noticed that airport signage using the simplest method can help people communicate with one another more easily. He began to collect and organize airport signs and airplane safety guides that are designed with readable pictures.`,
    footerText1:`Book from the Ground Software`,
    footerText2:`Book from the Ground Pop-up Book`,
    footerText3:`MetaWords, an NFT art project, is the evolution of Book from the Ground in the Metaverse. MetaWords aims to invent an entirely new language system of the future Metaverse–The “Meta-language” of the Metaverse.`,
    footerText4:`Inspired by environmental protection images on gum wrappers where pictographs are used to narrate long-form stories, Xu started to make Book from the Ground`,
    footerText5:`Book from the Ground: From Point to Point Publication and Distribution`,
    footerText6:`Book from the Ground: From Point to Point Published in languages from 8 different countries and regions.Print 2003-2019`,



}
export {book}