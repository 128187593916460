import {aboriginal} from './aboriginal';
import {about} from './about';
import {book} from './book';
import {nav} from './nav';
import {aboutUs} from './aboutUs';
export default{
    aboriginal,
    about,
    book,
    nav,
    aboutUs
}