const aboriginal = {
    content:{
        title:`We are pleased to announce the official launch of the MetaWords Inhabitant Program which serves as a coordinated effort to provide better resources to our community and friends who continue to support and grow with MetaWords. `,
        title1:`Who are we looking for? Community friends who are…`,
        title2:`- Equally excited and passionate about MetaWords as we are and who understand the promising future that we are building for MetaWords.`,
        title3:` With the promising development of MetaWords, we hope to see more of you join the community and contribute to this thriving new market!`,
        title4:`- Experienced in community promotion and management; capable of taking on responsibility and initiative in MetaWords’ growth and development.`,
        title5:`What benefits and rights do MetaWords Inhabitants have?`,
        title6:`There’re four levels of Inhabitants: Common, Silver, Gold, Platinum.`,
        title7:`Flying Lion Lab will continue helping the MetaWords community achieve its autonomy. We would like to thank the MetaWords Inhabitants for generously providing us with your ideas and suggestions in the hopes of growing and building a better MetaWords community together.`,
        pic_one:`Common`,
        content:`Invite 1 new user to purchase Blind Boxes`,
        title_one:`Title`,
        title_one1:`Common Inhabitant`,
        title_one2:`Sales Reward`,
        title_one3:`10% Per Sale`,
        pic_two:`Silver`,
        title_two:`Invite 20 new users to purchase Blind Boxes`,
        title_two1:`Title`,
        title_two2:`Silver Inhabitant`,
        title_two3:`Sales Reward`,
        title_two4:`15% Per Sale`,
        title_two5:`Community Governing`,
        title_two6:`Participate in Inhabitant Election`,
        pic_three:`Gold`,
        title_three1:`Invite 50 new users to purchase Blind Boxes`,
        title_three2:`Sales Reward`,
        title_three3:`20% Per Sale`,
        title_three4:`Promotional Events`,
        title_three5:`Participate in important promotional events`,
        title_three6:`Original work`,
        title_three7:`Propose to create 1 original MetaWords Character`,
        title_three8:`And all Silver Inhabitants’ benefits.`,
        pic_four:`Platinum`,
        title_four1:`Invite 100 new users to purchase Blind Boxes`,
        title_four2:`Promotional Events`,
        title_four3:`Propose, organize and carry out important promotional events as well as request for funding`,
        title_four4:`Original Work`,
        title_four5:`Propose to create two original`,
        title_four6:`MetaWords Characters`,
        title_four7:`And all Gold Inhabitants’ benefits`,
        footer:`Please get in touch with our community advisors if you are interested in the MetaWords Inhabitant Program. Events and promotions are subject to change, please follow MetaWords official announcements for updates. MetaWords reserve the right, at its sole discretion, to change, modify or cancel promotions without notice.`,
        soon:`Coming Soon`,
    }

}
export {aboriginal}